/* Selected Product Page Styles */
.selected-product-container {
  background-color: #f9f9f9;
}

.product-details {
  display: flex;
  gap: 1.5em;
  align-items: flex-start;
  margin-bottom: 1.5em;
  padding: 1.5em;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping for description positioning */
}

.product-image {
  flex: 0 0 35%;
  max-height: 400px;
}

.product-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-info-container {
  flex: 0 0 60%;
  min-height: 80vh;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  order: 1; /* Default order for product info */
}

.product-info {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.product-info h1 {
  font-size: 2em;
  color: #333;
}

.product-description {
  font-size: 1em;
  color: #666;
  margin-top: 1em;
  order: 2; /* Place description below image on larger screens */
  width: 100%; /* Full width for description */
}

.product-price {
  font-size: 1.4em;
  color: #f57c00;
  font-weight: bold;
  margin-top: 0.2em;
}

.read-more-btn {
  background-color: transparent;
  border: none;
  color: #f57c00;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
}

.related-products {
  margin-top: 1.5em;
}

.related-products h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 1em;
  text-align: center;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
}

.products-list .card {
  flex: 1 1 calc(25% - 1em);
  max-width: 300px;
  box-sizing: border-box;
}

/* Media Queries */
@media (max-width: 1024px) {
  .product-details {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
    text-align: center;
  }

  .product-image {
    width: 80%;
  }

  .product-info-container {
    padding: 0;
    width: 100%;
    order: 0; /* Reset order for smaller screens */
  }

  .product-description {
    order: 0; /* Keep description in default position */
  }

  .product-info h1 {
    font-size: 1.8em;
  }

  .products-list .card {
    flex: 1 1 calc(50% - 1em); /* Display two cards per row */
  }
}

@media (max-width: 768px) {
  .product-details {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    text-align: center;
  }

  .product-image {
    width: 80%;
  }

  .product-info-container {
    padding: 0;
    width: 100%;
    order: 0; /* Reset order for smaller screens */
  }

  .product-description {
    order: 0; /* Keep description in default position */
  }

  .product-info h1 {
    font-size: 1.8em;
  }

  .products-list .card {
    flex: 1 1 calc(50% - 1em); /* Display two cards per row */
  }
}

@media (max-width: 480px) {
  .product-details {
    padding: 1em;
  }

  .product-image {
    width: 100%;
  }

  .product-info h1 {
    font-size: 1.6em;
  }

  .product-price {
    font-size: 1.2em;
  }

  .products-list .card {
    flex: 1 1 100%; /* Display one card per row */
  }
}
